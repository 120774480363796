<script>
import { computed, inject, onBeforeUnmount, onMounted, reactive, ref, unref, watch, toRaw } from "vue";
import ToolPaintProperties from "./paint-properties/ToolPaintProperties.vue";
import ActiveProjectContent from "../common/ActiveProjectContent.vue";
import { ICON, TOOL_GROUPS } from "../common/constants.js";
import { useRoute, useRouter } from "vue-router";
import ToolButton from "../common/ToolButton.vue";
import DocumentDetails from "./DocumentDetails.vue";
import SideBar from "../common/SideBar/SideBar.vue";
import AnnotationPaintProperties from "./paint-properties/AnnotationPaintProperties.vue";
import { ANNO_TYPES, BROADCAST_MESSAGE, ERROR_TOAST, INFO_TOAST, PUBLIC_VIEWER, VIEWER, BUTTON_ROLES } from "../../core/constants.js";
import { mutations, getters } from "../../store/index.js";
import SearchWithinDocument from "./search-within-document.vue";
import SmartScrollBar from "./SmartScrollBar.vue";
import LeftSideBar from "../common/SideBar/LeftSideBar.vue";
import StampsManager from "./stamps-manager/StampsManager.vue";
import ProjectService from "../../service/projectService";
import AnnotationService from "../../service/annotationService";
import { isValidAutomergeUrl } from "@automerge/automerge-repo";
import Toolbar from "./toolbar/Toolbar.vue";
import { GET_CACHE, SET_CACHE } from '../../store/cacheLayer';
import { AP_CACHE_SET } from '../../store/annotationPanel';
import { v4 as uuid } from "uuid";
import CreateStampDialog from "../common/modals/stamps/CreateStampDialog.vue";
import AnnotationPanel from '../common/AnnotationPanel/APMain.vue';
import moment from 'moment';
import { state as AP_State, AP_CACHE_GET } from '../../store/annotationPanel';

export default {
  components: {
    StampsManager,
    LeftSideBar,
    SmartScrollBar, SearchWithinDocument, AnnotationPaintProperties, SideBar,
    ToolPaintProperties, ToolButton, DocumentDetails, ActiveProjectContent,
    Toolbar,
    CreateStampDialog,
    AnnotationPanel
  },
  setup() {
    const $fetch = inject("$fetch");
    const route = useRoute();
    const router = useRouter();

    const rootEl = ref(null);
    const isLoading = ref(false)
    const showDocumentDetails = ref(false)
    const currentDocument = reactive({
      permissions: [],
      objects: [],
      content: null,
      document: null,
      userRole: VIEWER,
      userName: '',
      userEmail: '',
      documentName: '',
      meta: {}
    });
    const currentCollaborators = reactive({
      collaborators: {}
    })
    const currentPage = ref(1);
    const countPages = ref(0);
    const zoomValue = ref(100);
    const temporaryHideFloatingBar = ref(false);
    const groupButtonIndex = ref(0);
    const selectedAnnotations = reactive({
      drawingProps: null, parentRects: null, canDelete: false, info: {}
    });
    const stampsManagerState = reactive({
      shown: false,
    });
    const canUndo = ref(false);
    const canRedo = ref(false);
    const unsubscribe = reactive({ fn: null });

    const { FoliaPDFViewer } = window.FoliaPdfViewer;
    const foliaPdfViewer = new FoliaPDFViewer();
    window.foliaPdfViewer = foliaPdfViewer;

    const UNDO = { title: "Undo", icon: ICON.UNDO };
    const REDO = { title: "Redo", icon: ICON.REDO };
    const NO_SELECTED_TOOL = { name: "NO_SELECTED_TOOL", title: "", icon: ICON.NO_SELECTED_TOOL };
    const EXPAND_OBJECTS_PANEL = { title: "Expand objects panel", icon: ICON.EXPAND };

    const groupButtons = [
      NO_SELECTED_TOOL,
      "|",
      TOOL_GROUPS.PENCIL,
      TOOL_GROUPS.HIGHLIGHT,
      TOOL_GROUPS.COMMENT,
      TOOL_GROUPS.STAMPS,
      "|",
      TOOL_GROUPS.SHAPE,
      TOOL_GROUPS.TEXT_BOX,
      TOOL_GROUPS.IMAGE,
      TOOL_GROUPS.ERASER,
    ];

    const repo = toRaw(getters.automergeRepo);
    const { projectId, documentId } = route.params;
    const projectService = new ProjectService();
    const annotationService = new AnnotationService()
    const pageFullyLoaded = ref(false);
    const createStampState = reactive({
      showDialog: false,
      data: {}
    });
    const canAnnotate = ref(false);
    const isDocumentDataLoading = ref(true);
    const smartScrollBar = ref(null);
    const connectionLost = ref(false);
    const isAPOpen = ref(AP_State.isAPOpen ?? false);

    onMounted(async () => {
      connectionLost.value = false;
      await getDocumentInfo();
      if (!canAnnotate.value) return;
      isLoading.value = true;
      fetchCollaborators(projectId);
      mutations.toggleUploadMonitorShown(false);
      $fetch.on(BROADCAST_MESSAGE.DOCUMENT_UPDATED, documentUpdated);
      $fetch.on(BROADCAST_MESSAGE.PROJECT_UPDATED, projectUpdated);
      const ui = {
        container: rootEl.value.querySelector('#viewerContainer'),
        viewer: rootEl.value.querySelector('#viewer'),
        thumbnailView: rootEl.value.querySelector('.thumbnail-pages')
      };
      const { userEmail, userName, userRole, permissions } = await getDocumentData({ projectId });
      const dataProxy = {
        get userEmail() { return userEmail.toLowerCase(); },
        get userName() { return userName },
        get userRole() { return userRole },
        get documentId() { return documentId; },
        get permissions() { return permissions },
        getObjects(pageNumber) {
          const { projectId, id: documentId } = currentDocument.document;
          return $fetch.getObjects(documentId)
            .then((objects) => {
              // console.log("getObjects", objects.map(o => `${o.__typename} ${o.id}: ${o.replies?.map(r => r.text).join(", ")}`).join(", "));
              return objects.filter((object) => object.page === pageNumber);
            })
            .catch(err => {
              console.error("Can not retrieve objects", err.message);
              return [];
            });
        },
      };
      await foliaPdfViewer.init(ui, dataProxy);
      foliaPdfViewer.eventBus.on("documentloaded", onDocumentLoaded);
      foliaPdfViewer.eventBus.on("pagechanging", onPageChanging);
      foliaPdfViewer.eventBus.on("scalechanging", onScaleChanging);
      foliaPdfViewer.eventBus.on("floatingbarhide", onHideFloatingBar);
      foliaPdfViewer.eventBus.on("floatingbarshow", onShowFloatingBar);
      foliaPdfViewer.eventBus.on("pagesloaded", onPagesLoaded);
      foliaPdfViewer.eventBus.on("stop-drawing", onStopDrawing);
      foliaPdfViewer.eventBus.on("undo-redo-changed", onUndoRedoChanged);
      foliaPdfViewer.eventBus.on("set-replies-read-status", onSetRepliesReadStatus);
      foliaPdfViewer.eventBus.on("folia-data", getObjects);
      foliaPdfViewer.eventBus.on("objects-were-updated", objectsWereUpdated);
      foliaPdfViewer.eventBus.on("make-as-a-stamp", createStampFromAnnotation);
      foliaPdfViewer.eventBus.on("attach-comment-for-annotation", handleCommentThread);
      foliaPdfViewer.eventBus.on("comment-attached", afterCommentAttachedToAnnotation);


      await initializeAutoMergeCodeHere();

      openDocument(route.params.documentId)
        .then(() => isLoading.value = false)
        .catch(console.error);
      // $fetch.retrieveToolPresets()
      //   .then(presets => localStorage.setItem("tool-presets", JSON.stringify(presets)))
      //   .catch(console.error);

      document.addEventListener("wheel", onWheelListener, { passive: false });
      initializeAutoMergeCodeHere();
      pageFullyLoaded.value = true;
    });

    const handleCommentThread = async (payload) => {
      const { objects: annotationDataObjects, zoomScale } = payload;// unmodified object data fetched from floating bar.
      const { bounds, id: parentAnnotationId } = annotationDataObjects[0];
      const pageNumber = currentPage.value > 0 ? currentPage.value - 1 : 0
      useTool('COMMENT', { bounds, parentAnnotationId, pageNumber });
    }
    const afterCommentAttachedToAnnotation = async (data) => {
      const { parentAnnotationId, commentId } = data;
      const parentObject = currentDocument.objects.find(o => o.id === parentAnnotationId);

    }
    const purgeAutomergeData = () => {
      handle.change(d => d.annotations = {})
    }
    async function createStampFromAnnotation(data) {
      const { id: annotationId } = data;
      const annotationsResponse = await annotationService.getAnnotations({ projectId, documentId }, handle);
      let selectedAnnotation = null;
      annotationsResponse.some(anno => {
        if (anno.id && anno.id === annotationId) {
          selectedAnnotation = anno;
          return true;
        }
        return false
      })
      if (selectedAnnotation) {
        createStampState.showDialog = true;
        createStampState.data = selectedAnnotation;
      }
    }
    async function getDocumentInfo() {
      isDocumentDataLoading.value = true;
      const cachedDoc = await GET_CACHE.getCachedDocument(documentId);
      if (cachedDoc) {
        const docData = cachedDoc.data || {};
        currentDocument.documentName = docData.name;
        const userName = docData.uploaderName || docData.uploadedBy;
        const userEmail = docData.uploadedBy;
        currentDocument.meta = {
          ...docData,
          userName,
          userEmail,
          nameTag: userEmail.slice(0, 2).toUpperCase(),
          formattedCreatedAt: docData.createdAt ? `${moment(docData.createdAt).format('MMM DD YYYY')} at ${moment(docData.createdAt).format('hh:mm A')}` : 'N/A',
          formattedUpdatedAt: docData.updatedAt ? `${moment(docData.updatedAt).format('MMM DD YYYY')} at ${moment(docData.updatedAt).format('hh:mm A')}` : 'N/A',
          formattedSourceSize: `${(docData.sourceSize / (1024 * 1024)).toFixed(2)} MB`
        }
      }
      else {
        const docData = await projectService.getDocument({ documentId });
        currentDocument.documentName = docData.name;
        const userName = docData.uploaderName || docData.uploadedBy;
        const userEmail = docData.uploadedBy;
        currentDocument.meta = {
          ...docData,
          userName,
          userEmail,
          nameTag: userEmail.slice(0, 2).toUpperCase(),
          formattedCreatedAt: docData.createdAt ? `${moment(docData.createdAt).format('MMM DD YYYY')} at ${moment(docData.createdAt).format('hh:mm A')}` : 'N/A',
          formattedUpdatedAt: docData.updatedAt ? `${moment(docData.updatedAt).format('MMM DD YYYY')} at ${moment(docData.updatedAt).format('hh:mm A')}` : 'N/A',
          formattedSourceSize: `${(docData.sourceSize / 1024).toFixed(2)} MB`
        }
      }
      isDocumentDataLoading.value = false;
      const project = await GET_CACHE.getCachedProject(projectId);
      currentDocument.projectName = project?.name;
      canAnnotate.value = currentDocument.meta?.isAnnotatable
    }
    watch(() => route.params.documentId, async (value) => {
      if (!value) return
      groupButtonIndex.value = 0;
      selectedAnnotations.drawingProps = null;
      selectedAnnotations.parentRects = null;
      selectedAnnotations.canDelete = false;
      selectedAnnotations.info = {};
      selectedAnnotations.hasError = false;
      await openDocument(value);
    });

    onBeforeUnmount(async () => {
      foliaPdfViewer.stopDrawing();
      foliaPdfViewer.resetObjectsSeletion();

      document.removeEventListener("wheel", onWheelListener, { passive: false });
      foliaPdfViewer.eventBus.off("documentloaded", onDocumentLoaded);
      foliaPdfViewer.eventBus.off("pagechanging", onPageChanging);
      foliaPdfViewer.eventBus.off("scalechanging", onScaleChanging);
      foliaPdfViewer.eventBus.off("floatingbarhide", onHideFloatingBar);
      foliaPdfViewer.eventBus.off("floatingbarshow", onShowFloatingBar);
      foliaPdfViewer.eventBus.off("pagesloaded", onPagesLoaded);
      foliaPdfViewer.eventBus.off("stop-drawing", onStopDrawing);
      foliaPdfViewer.eventBus.off("undo-redo-changed", onUndoRedoChanged);
      foliaPdfViewer.eventBus.off("folia-data", getObjects);
      foliaPdfViewer.eventBus.off("objects-were-updated", objectsWereUpdated);

      $fetch.off(BROADCAST_MESSAGE.PROJECT_UPDATED, projectUpdated);
      $fetch.off(BROADCAST_MESSAGE.DOCUMENT_UPDATED, documentUpdated);
      foliaPdfViewer.deinit();
      if (typeof unsubscribe.fn === "function") await unsubscribe.fn();
    })
    async function getDocumentData() {
      // here API data fetched and retuned
      const { permissions, owner, userEmail, userName, userRole } = await annotationService.getDocumentData({ documentId, projectId });
      currentDocument.document = {
        id: documentId,
        projectId
      }
      console.log({ currentDocument });

      currentDocument.owner = owner;
      currentDocument.permissions = permissions;
      currentDocument.userEmail = userEmail;
      currentDocument.userName = userName;
      currentDocument.userRole = userRole;
      // set tool preset
      const presets = await annotationService.getToolPreset({});
      return { permissions, owner, userEmail, userName, userRole }
    }

    async function fetchCollaborators(projectId) {
      const { collaborators = [], isOwner, publicStatus } =
        await projectService.getProjectCollaborators({ projectId });
      collaborators.forEach(c => currentCollaborators.collaborators[c.email] = c);
    }

    function groupBy(arr, property) {
      return arr.reduce((groups, item) => {
        const value = item[property];
        groups[value] = groups[value] || [];
        groups[value].push(item);
        return groups;
      }, {});
    }
    async function getObjects(props) {
      const { responseName, reqProps: { pageNumber } } = props;
      const annotationsResponse = await annotationService.getAnnotations({ projectId, documentId }, handle);
      const replies = groupBy(annotationsResponse.filter(r => r.__typename === "ReplyAnnotation"), 'commentId');
      const annotations = annotationsResponse.filter(a => a.__typename !== "ReplyAnnotation").map(annotation => {
        const { id: commentId } = annotation;
        if (replies[commentId]) {
          annotation.replies = replies[commentId];
        }
        return annotation;
      })
      const data = {
        permissions: currentDocument.permissions,
        userEmail: currentDocument.userEmail,
        userName: currentDocument.userName,
        objects: annotations.filter((object) => object.page === pageNumber)
          .map(ann => {
            const collaborator = currentCollaborators.collaborators[ann.collaboratorEmail];
            ann.collaboratorName = collaborator ? collaborator.name : ann.collaboratorEmail;
            return ann;
          }),
      }
      annotationsResponse.forEach(o => {
        const existingObjectIndex = currentDocument.objects.findIndex(co => co.id === o.id);

        if (existingObjectIndex > -1) {
          // Object exists, update it
          currentDocument.objects[existingObjectIndex] = o;
        } else {
          // Object does not exist, add it
          currentDocument.objects.push(o);
        }
      });
      const withoutDeletedObjects = currentDocument.objects.filter(o => !o.deletedAt);
      const filteredObjects = withoutDeletedObjects.filter(o => o.__typename !== "ReplyAnnotation")
      currentDocument.objects = filteredObjects;
      AP_CACHE_SET.setCurrentPanel({ panel: "ANNOTATIONS", data: { ...data, objects: filteredObjects } });
      // console.log({annotations,responseName, data});
      return annotations ? foliaPdfViewer.eventBus.dispatch(responseName, data) : [];
    }
    let postObjectDataTimer;
    const callPdfLayerToMoveComment = (anchorPoint, annotationObject) => {
      foliaPdfViewer.eventBus.dispatch("move-comment-annotation", {
        anchorPoint,
        annotationObject
      });
    }

    async function objectsWereUpdated(objectsData) {
      const { projectId, id: documentId } = currentDocument.document;
      for (const objectData of objectsData) {
        if (objectData === ANNO_TYPES.COMMENT) {
          delete objectData.replies;
        } else {
          currentDocument.objects.forEach(async currentObject => {
            // console.log(currentObject.parentAnnotationId,objectData.id);
            if (currentObject.parentAnnotationId && currentObject.parentAnnotationId === objectData.id) {
              // when the object is deleted
              if (objectData.deletedAt && objectData.deletedAt !== "") {
                // delete the comment if found...
                foliaPdfViewer.eventBus.dispatch("delete-comment-annotation", { parentAnnotationId: objectData.id, commentId: currentObject.id });
              } else {
                const yOffset = -0.03
                switch (objectData.__typename) {
                  case "SquareAnnotation":
                    callPdfLayerToMoveComment([objectData.rect[2], objectData.rect[1] + yOffset], currentObject)
                    break;
                  case "CircleAnnotation":
                    callPdfLayerToMoveComment([objectData.rect[2], objectData.rect[1] + yOffset], currentObject)
                    break;
                  // file deepcode ignore DuplicateCaseBody: <please specify a reason of ignoring this>
                  case "ArrowAnnotation":
                    callPdfLayerToMoveComment([objectData.rect[2], objectData.rect[1] + yOffset], currentObject)
                    break;
                  case "InkAnnotation":
                    callPdfLayerToMoveComment([objectData.paths[0][0], objectData.paths[0][1]], currentObject)
                    break;
                  case "TextBoxAnnotation":
                    callPdfLayerToMoveComment([objectData.rect[2], objectData.rect[1] + yOffset], currentObject)
                    break;
                  case "ImageAnnotation":
                    callPdfLayerToMoveComment([objectData.rect[2], objectData.rect[1] + yOffset], currentObject)
                    break;
                  default:
                    break;
                }
              }


            }
          })
        }
        await annotationService.createAnnotation(projectId, documentId, objectData, handle);
      }


      // clearTimeout(postObjectDataTimer);
      // for (const objectData of objectsData) {
      //   if (objectData === ANNO_TYPES.COMMENT) {
      //     delete objectData.replies;
      //   }
      //   // console.log("objectsWereUpdated", objectData.rect)
      //   $fetch.updateObject(projectId, documentId, objectData).catch(console.error);
      // }
      // postObjectDataTimer = setTimeout(() => {
      //   // $fetch.outputSyncDocument(projectId, documentId);
      // }, 1000);
    }

    async function initializeAutoMergeCodeHere() {

      const docId = await projectService.getDocumentAnnotationDocId({ documentId });
      let handle;
      if (docId) {
        handle = repo.find(docId);
      }
      else {
        connectionLost.value = true;
        // handle = repo.create({ annotations: {} });
        // handle.change(d => d.annotations = {})
        // handle.change((d) => (d.changeLog = autoMergeValue));
      }

      // const annotationDocumentId = handle.url;
      // let updateAnnoIdResp = null;

      // if (docId === null) {
      //   try {
      //     updateAnnoIdResp = await projectService.updateDocumentAnnotationDocId({
      //       projectId,
      //       documentId,
      //       annotationDocumentId
      //     });
      //   } catch (error) {
      //     const message = 'Your changes might not be saved.'
      //     $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
      //       type: ERROR_TOAST, title: 'Error while syncing your document.', message
      //     })
      //   }
      // }
      // @ts-ignore
      window.handle = handle;
      repo.networkSubsystem.on('message', async (msg) => {
        syncDoc();
      })
    }

    function syncDoc() {
      const docValue = handle.docSync();
      // console.log("Automerge sync");
      // console.log(docValue);
      foliaPdfViewer.refreshFoliaLayers();
      foliaPdfViewer.eventBus.dispatch("refresh-folia-page");
    }

    async function projectUpdated(projectId) {
      // console.log("projectUpdated", projectId);
      const { documentId } = route.params;
      if (projectId !== route.params.projectId) return;
      const owner = await $fetch.getUserProfile();
      let project = await $fetch.getProject(projectId);
      if (!project) {
        await $fetch.inputSyncProject(projectId);
        project = await $fetch.getProject(projectId);
      }
      if (!project) return;
      await Promise.allSettled([
        $fetch.getDocuments(projectId),
        Promise.resolve(documentId),
      ]).then((results) => {
        // const [documents, documentId] = results.map(r => r.value);
        // const needUpdate = currentDocument.userRole !== project.userRole;
        // currentDocument.owner = 'srt.sahan@gmail.com';
        // currentDocument.userRole = 'OWNER';
        // currentDocument.projectName = 'test p';
        // const permissions = [
        //   "MANAGE_ANNOTATION",
        //   "DELETE_FOREIGN_ANNOTATION",
        //   "MANAGE_OWN_COMMENT",
        //   "DELETE_FOREIGN_COMMENT",
        //   "PUBLISH_PROJECT",
        //   "SHARE_PROJECT",
        //   "RENAME_PROJECT",
        //   "DELETE_PROJECT",
        //   "ARCHIVE_PROJECT",
        //   "DUPLICATE_PROJECT",
        //   "UPLOAD_DOCUMENT",
        //   "RENAME_DOCUMENT",
        //   "DELETE_DOCUMENT",
        //   "COPY_DOCUMENT",
        //   "DOWNLOAD_SOURCE_DOCUMENT",
        //   "DOWNLOAD_ORIGINAL_DOCUMENT",
        //   "DOWNLOAD_ANNOTATED_DOCUMENT",
        // ]
        // currentDocument.permissions = permissions;
        // currentDocument.documents = documents;
        // if (documentId === route.params.documentId) {
        //   currentDocument.document = documents.find(d => d.id === documentId);
        // }
        // if (needUpdate) {
        //   // foliaPdfViewer.refreshFoliaLayers();
        //   foliaPdfViewer.eventBus.dispatch("project-updated", {
        //     permissions: currentDocument.permissions,
        //     userEmail: currentDocument.owner.email,
        //     userName: currentDocument.owner.name,
        //   });
        // }
      })
    }
    async function documentUpdated(documentId) {
      // console.log("documentUpdated", documentId);
      foliaPdfViewer.eventBus.dispatch("refresh-folia-page");
    }
    function onSetRepliesReadStatus(statuses = []) {
      if (statuses.length === 0) return;
      const { id: documentId, projectId } = currentDocument.document;
      $fetch.setRepliesReadStatus(statuses.map(status => ({ documentId, projectId, ...status })));
    }

    let zoomTimerId, zoom;
    function onWheelListener(e) {
      if (e.ctrlKey) {
        e.preventDefault();
        selectGroup(0);
        const viewerDiv = foliaPdfViewer.pdfViewer.container;
        clearTimeout(zoomTimerId);
        const zoomFactor = 0.025;
        let zoomDirection = 0;
        zoom = zoom || 1;
        if (e.deltaY < 0) {
          zoomDirection = 1
        } else if (e.deltaY > 0) {
          zoomDirection = -1
        }
        zoom = parseFloat((zoom + zoomFactor * zoomDirection).toFixed(2));
        viewerDiv.style.zoom = zoom;

        zoomTimerId = setTimeout(() => {
          // prettier-ignore
          foliaPdfViewer.pdfViewer.currentScaleValue = foliaPdfViewer.pdfViewer.currentScale * zoom;
          foliaPdfViewer.forceRendering();
          zoom = 1
          viewerDiv.style.zoom = zoom
        }, 250)

      }
    }

    // end of api requests
    function onStopDrawing() {
      selectGroup(0);
    }
    function onUndoRedoChanged(state) {
      canUndo.value = state.canUndo;
      canRedo.value = state.canRedo;
    }
    function onPagesLoaded() {
      foliaPdfViewer.checkForNativeAnnotationsPresence()
        .then((hasAnnotations) => {
          if (!hasAnnotations) return;
          // const checkedDocuments = new Set(localStorage.getItem("checkedDocuments")?.split(","));
          // if (checkedDocuments.has(route.params.documentId)) return;
          // $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
          //   type: INFO_TOAST,
          //   title: "Document contains external annotations.",
          //   message: `The document contains PDF annotations that were created
          //   outside of Folia. These annotations cannot be edited.`
          // });
          // checkedDocuments.add(route.params.documentId);
          // localStorage.setItem("checkedDocuments", Array.from(checkedDocuments).join(","));
        });
    }
    function onDocumentLoaded(e) {
      currentPage.value = e.source.page;
      countPages.value = e.source.pagesCount;
      zoomValue.value = parseInt(e.source.zoom, 10);
      isLoading.value = false;
      setTimeout(() => {
        const { page, object } = route.query;
        if (page && !object) {
          foliaPdfViewer.page = parseInt(page.toString(), 10);
        } else if (page && object) {
          foliaPdfViewer.selectAnnotationObject(page, object)
        }
      }, 10)
    }
    function onPageChanging(e) {
      currentPage.value = e.pageNumber;
    }
    function onScaleChanging(e) {
      zoomValue.value = parseInt(e.scale * 100, 10);
    }
    function scrollHandlerWhileToolbarShown() {
      onShowFloatingBar({ objects: window._objects })
    }
    function onHideFloatingBar() {
      // console.log("onHideFloatingBar", []);
      document.querySelector("#viewerContainer").removeEventListener("scroll", scrollHandlerWhileToolbarShown)
      delete window._objects;
      selectedAnnotations.drawingProps = null;
      selectedAnnotations.parentRects = null;
      selectedAnnotations.canDelete = false;
      selectedAnnotations.info = {};
      selectedAnnotations.hasError = false;
    }
    function onShowFloatingBar({ objects }) {
      console.log("Calling to show floating bar");
      window._objects = objects;
      document.querySelector("#viewerContainer").addEventListener("scroll", scrollHandlerWhileToolbarShown);
      const hasComment = objects.some(obj => obj.annotationRawData.__typename === "CommentAnnotation");
      selectGroup(0);
      if (hasComment) {
        return onHideFloatingBar.call();
      }
      let pageEl;
      ///------------------------------------------------------------------
      const annoRect = objects.reduce((acc, obj) => {
        pageEl = obj.foliaLayer;
        const { clientWidth, clientHeight, offsetLeft, offsetTop } = obj.annotationDIV;
        const rect = {
          width: clientWidth, height: clientHeight, left: offsetLeft, top: offsetTop,
          right: offsetLeft + clientWidth, bottom: offsetTop + clientHeight,
        };
        return {
          left: Math.min(acc.left, rect.left), top: Math.min(acc.top, rect.top),
          right: Math.max(acc.right, rect.right), bottom: Math.max(acc.bottom, rect.bottom),
          width: Math.max(acc.right, rect.right) - Math.min(acc.left, rect.left),
          height: Math.max(acc.bottom, rect.bottom) - Math.min(acc.top, rect.top),
          scale: obj.viewport.scale,
        };
      }, { left: Infinity, top: Infinity, right: -Infinity, bottom: -Infinity });

      let parentEl = pageEl.parentNode, offsetLeft = 0, offsetTop = 0;
      const stopWord = document.querySelector(".document-container");
      do {
        offsetLeft += parentEl?.offsetLeft || 0;
        offsetTop += (parentEl?.offsetTop || 0) - (parentEl?.scrollTop || 0);
        parentEl = parentEl?.parentNode;
      } while (parentEl && parentEl !== stopWord);

      const pageRect = {
        width: pageEl.clientWidth,
        height: pageEl.clientHeight,
        offsetLeft,
        offsetTop,
      };
      // console.log(pageRect)
      ///------------------------------------------------------------------
      const annotData = objects.map(object => {
        return { ...object.getRawData() };
      });

      selectedAnnotations.parentRects = { pageRect, annoRect, annotData };

      selectedAnnotations.drawingProps = objects.reduce((acc, obj) => {
        return Object.assign(acc || {}, obj.editableProperties.get());
      }, null);

      selectedAnnotations.canDelete = objects.reduce((acc, obj) => {
        return acc && obj.canDelete;
      }, true);

      selectedAnnotations.annotationTypes = objects.reduce((acc, obj) => {
        acc.add(obj.annotationRawData.__typename);
        return acc;
      }, new Set());

      selectedAnnotations.annotationIds = objects.reduce((acc, obj) => {
        acc.add(obj.annotationRawData.id);
        return acc;
      }, new Set());

      selectedAnnotations.hasError = objects.some(obj => obj.annotationRawData.error);
      // console.log("show floating bar", objects);
      const { projectId } = route.params
      $fetch.getProjectCollaborators(projectId)
        .then(({ collaborators }) => {
          const authors = Array.from(new Set(objects.map(obj => {
            const user = collaborators.find(c => c.email === obj.author);
            return user?.profile?.username || obj.author
          })));

          const author = authors.length < 3
            ? authors.join(", ")
            : authors.slice(0, 3).join(", ") + (
              authors.length > 3 ? ` and ${authors.length - 3} more` : ``
            );
          const addedAt = objects.at(-1)?.addedAt;
          selectedAnnotations.info = { author, addedAt }
        });
    }

    async function openDocument(documentId) {
      try {
        const { content } = await projectService.downloadDocumentForPDFJS({ documentId });
        if (content) {
          await foliaPdfViewer.close();
          await foliaPdfViewer.open(content);
          // unsubscribe.fn = $fetch.documentChangesSubscription(documentId);
        }
        // const { projectId } = route.params
        // await projectUpdated(projectId);
        // await $fetch.inputSyncDocument(projectId, documentId);
        // const content = currentDocument.content || await $fetch.getDocumentPDF(documentId);
        // currentDocument.content = content;
        // if (content) {
        //   await foliaPdfViewer.close();
        //   await foliaPdfViewer.open(content);
        //   unsubscribe.fn = $fetch.documentChangesSubscription(documentId);
        // } else {
        //   try {
        //     await $fetch.inputSyncDocument(projectId, documentId)
        //   } catch (e) {
        //     return await router.replace(`/p/${projectId}`);
        //   }
        //   setTimeout(async () => await openDocument(documentId), 1000);
        // }
      } catch (e) {
        console.error(e);
        $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
          type: ERROR_TOAST,
          title: "Error while open document",
          message: e.message,
        });
      }
    }

    const showToolPaintProperties = computed(() => {
      if (temporaryHideFloatingBar.value === true) return false;
      if (groupButtonIndex.value === 0) return false;
      const group = groupButtons[groupButtonIndex.value];
      const tool = group?.tools[0];
      return tool && tool.presets;
    })
    function selectGroup(groupIndex) {
      if (groupButtonIndex.value === groupIndex) {
        temporaryHideFloatingBar.value = !temporaryHideFloatingBar.value;
        return;
      }
      temporaryHideFloatingBar.value = false;
      groupButtonIndex.value = groupIndex;
      foliaPdfViewer.stopDrawing();
      stampsManagerState.shown = false;

      if (groupIndex > 0) {
        foliaPdfViewer.resetObjectsSeletion();
        selectedAnnotations.drawingProps = null;
        selectedAnnotations.parentRects = null;
        selectedAnnotations.canDelete = false;
        selectedAnnotations.info = {};
        selectedAnnotations.hasError = false;
        if (!showToolPaintProperties.value) {
          const group = groupButtons[groupIndex];
          const tool = group?.tools[0];
          changeTool(tool);
        }
      }
    }
    function changeTool(tool, preset = {}) {
      foliaPdfViewer.stopDrawing();
      if (tool?.name === TOOL_GROUPS.STAMPS.name) {
        stampsManagerState.shown = true;
      } else {
        foliaPdfViewer.startDrawing(tool?.name, preset);
      }
    }
    function updatePresets(presets) {
      $fetch.updateToolPresets(presets).then(data => {
        localStorage.setItem("tool-presets", JSON.stringify(data));
      });
    }
    function updateDrawingProperties(preset = {}) {
      // console.log("updateDrawingProperties =>", preset);
      if (selectedAnnotations.drawingProps) {
        foliaPdfViewer.updateObjectsDrawingProperties(preset);
      } else if (groupButtonIndex.value > 0) {
        foliaPdfViewer.updateToolDrawingProperties(preset);
      }
    }
    function closeStaticToolbar() {
      stampsManagerState.shown = false;
      if (groupButtonIndex.value === 0) {
        foliaPdfViewer.resetObjectsSeletion();
      } else {
        selectGroup(0);
      }
    }
    async function useStamp(stampData) {
      const stamp = stampData.stamp;
      // if (stampData.preview instanceof Promise) {
      //   stampData.preview = await stampData.preview;
      // }
      stamp.id = uuid();
      stamp.addedAt = new Date().toISOString();
      stampsManagerState.shown = false;
      foliaPdfViewer.startDrawing(TOOL_GROUPS.STAMPS.name, stamp);
    }
    function zoomIn() {
      selectGroup(0);
      foliaPdfViewer.zoomIn(5);
    }
    function zoomOut() {
      selectGroup(0);
      foliaPdfViewer.zoomOut(5);
    }
    function deleteSelectedAnnotations() {
      foliaPdfViewer.deleteSelectedAnnotations()
    }
    function duplicateAnnot() {
      foliaPdfViewer.duplicateSelectedAnnotations()
    }
    function downloadDocument(documentId, flatten) {
      showDocumentDetails.value = false;
      $fetch.downloadDocument(projectId, documentId, flatten);
    }
    function useTool(tool, preset) {
      console.log("document use tool", tool, preset);
      foliaPdfViewer.stopDrawing();
      foliaPdfViewer.resetObjectsSeletion();
      if (tool === BUTTON_ROLES.STAMPS) {
        stampsManagerState.shown = true;
      } else if (tool !== BUTTON_ROLES.POINTER) {
        foliaPdfViewer.startDrawing(tool, preset);
      }
    }
    function undo() { foliaPdfViewer.undo(); }
    function redo() { foliaPdfViewer.redo(); }
    async function toggleDocumentFavoriteStatus(documentId, isFavorite) {
      // if (!currentDocument.document) return;
      // const addedAt = new Date().toISOString();
      // currentDocument.document.favorite.isFavorite = isFavorite;
      // const { projectId } = route.params;
      // $fetch.setDocumentFavoriteStatus(documentId, addedAt, isFavorite)
      //   .then(() => projectUpdated(projectId))
      //   .catch(console.error);
      currentDocument.meta.isFavorite = isFavorite;
      const email = localStorage.getItem("email");
      const tenantId = localStorage.getItem("tenantId");
      const documents = await GET_CACHE.getCachedDocuments(projectId, true);
      const document = documents.find(doc => doc.id === documentId);
      if (!document) return;
      document.isFavorite = isFavorite;
      await SET_CACHE.updateDoc({ documentId, data: document })
      await projectService.MarkDocumentFavorite({ documentId, isFavorite, tenantId, email });
    }

    const projectName = computed(() => {
      return currentDocument.projectName;
    });
    const fileName = computed(() => {
      return currentDocument.document?.name || "-";
    });
    const documentIsFavorite = computed(() => {
      return currentDocument.document?.isFavorite;
    });
    const canManageTools = computed(() => {
      if (!currentDocument.permissions) return false;
      return currentDocument.permissions.includes('MANAGE_ANNOTATION')
        || currentDocument.permissions.includes('MANAGE_OWN_COMMENT');
    });
    const favoriteDocuments = computed(() => {
      return (currentDocument.documents || []).filter(doc => doc.favorite.isFavorite);
    });
    const canChangeFavoriteStatus = computed(() => {
      return currentDocument?.userRole && currentDocument?.userRole !== PUBLIC_VIEWER;
    });
    async function downloadAnnotatedPDF() {

      const { content } = await projectService.downloadDocumentForPDFJS({ documentId })
      function downloadPDF() {
        const base64PDF = content.replace(/^data:application\/pdf;base64,/, '');
        const binaryPDF = atob(base64PDF);
        const arrayBuffer = new ArrayBuffer(binaryPDF.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryPDF.length; i++) {
          uint8Array[i] = binaryPDF.charCodeAt(i);
        }
        const blob = new Blob([uint8Array], { type: currentDocument.meta.originMimeType });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${currentDocument.documentName}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      downloadPDF();
    }
    const scrollToObject = (data) => {
      smartScrollBar.value.scrollToAnnotation(data)
    }
    const reloadPage = () => {
      window.location.reload();
    }
    const changeAPVisibility = () => {
      isAPOpen.value = !isAPOpen.value;
      localStorage.setItem('isAPOpen', isAPOpen.value);
      AP_CACHE_GET.changedPanelVisibility()
    }
    return {
      UNDO, REDO, NO_SELECTED_TOOL, EXPAND_OBJECTS_PANEL,
      foliaPdfViewer, rootEl, isLoading, currentDocument, showDocumentDetails,
      showToolPaintProperties, groupButtons, groupButtonIndex, selectedAnnotations,
      currentPage, countPages, zoomValue, canUndo, canRedo, canManageTools,
      projectName, fileName, documentIsFavorite, projectId, documentId,
      canChangeFavoriteStatus, favoriteDocuments, stampsManagerState, pageFullyLoaded, createStampState, isDocumentDataLoading, canAnnotate, smartScrollBar,
      connectionLost,

      selectGroup, updateDrawingProperties, duplicateAnnot, closeStaticToolbar,
      changeTool, updatePresets, zoomIn, zoomOut, deleteSelectedAnnotations,
      undo, redo, downloadDocument, toggleDocumentFavoriteStatus, useStamp, useTool, downloadAnnotatedPDF, scrollToObject,
      reloadPage, changeAPVisibility
    }
  },
}
</script>

<template>
  <DocumentDetails v-if="showDocumentDetails" :documentId="documentId" :userRole="currentDocument.userRole"
    @close="showDocumentDetails = false" @downloadDocument="downloadDocument" />
  <div class="document-container" ref="rootEl">
    <div class="document-info">
      <div class="document-info-name">
        <span class="info-project-name" @click.stop="$router.push(`/p/${projectId}`)" v-if="projectName">{{ projectName
        }}</span>
        <span class="info-file-name">{{ currentDocument.documentName }}</span>
      </div>

      <button v-if="canChangeFavoriteStatus" class="document-info-button" :class="{
        'isFavorite': currentDocument.meta?.isFavorite,
        'isNotFavorite': !currentDocument.meta?.isFavorite,
      }" @click.stop="toggleDocumentFavoriteStatus(documentId, !currentDocument.meta.isFavorite)"></button>
      <button v-if="canAnnotate" class="document-info-button info" @click.stop="showDocumentDetails = true"></button>
      <div class="absolute right-5 cursor-pointer sm:flex md:hidden ">
        <svg @click="changeAPVisibility" width="32" height="32" viewBox="0 0 32 32" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1_4268)">
            <path d="M13 10H24.25" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13 16H24.25" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13 22H24.25" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M7.75 10H9.25" stroke="#64748B" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M7.75 16H9.25" stroke="#64748B" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M7.75 22H9.25" stroke="#64748B" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_1_4268">
              <rect width="24" height="24" fill="white" transform="translate(4 4)" />
            </clipPath>
          </defs>
        </svg>
      </div>

      <div class="absolute right-2.5 cursor-pointer" v-if="!canManageTools">
        <svg @click="changeAPVisibility" width="32" height="32" viewBox="0 0 32 32" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1_4268)">
            <path d="M13 10H24.25" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13 16H24.25" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13 22H24.25" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M7.75 10H9.25" stroke="#64748B" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M7.75 16H9.25" stroke="#64748B" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M7.75 22H9.25" stroke="#64748B" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_1_4268">
              <rect width="24" height="24" fill="white" transform="translate(4 4)" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
    <div class="document-header border border-gray-200">
      <Toolbar v-if="pageFullyLoaded && canManageTools" @useTool="useTool" :canManageTools="canManageTools" />
      <div v-else class="min-h-[52px]"> 
        
      </div>

      <StampsManager v-if="stampsManagerState.shown" @close="closeStaticToolbar" @useStamp="useStamp" />

      <CreateStampDialog v-if="createStampState.showDialog" :annotationData="createStampState.data"
        @close="createStampState.showDialog = false" />
    </div>
    <!-- <div class="bg-red-600 min-h-[100px] max-h-[100px]">
    </div> -->

    <div class="fixed flex z-10 bg-slate-500 h-screen w-screen justify-center"
      v-if="!isDocumentDataLoading && connectionLost">
      <div class="mt-20 overflow-hidden rounded-lg bg-white shadow min-w-80 max-h-80 max-w-80 text-left text-gray-700">
        <div class="px-4 py-5 sm:p-6">
          <h1 class=" font-bold ">OOPS!!!</h1>
          <p class="text-gray-500 mt-6 text-sm font-semibold">You have lost your connection to the server please reload
            to connect again.</p>
        </div>
        <hr class="max-w-xs ml-6">
        <div class="p-6">
          <button type="button" @click="reloadPage"
            class="inline-flex items-center gap-x-1.5 rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            Reload
          </button>
        </div>
      </div>
    </div>
    <div v-if="!isDocumentDataLoading && !canAnnotate" class="h-svh">
      <div class="mt-20 overflow-hidden rounded-lg bg-white shadow min-w-96 text-left text-gray-700">
        <div class="px-4 py-5 sm:p-6">
          <h1 class=" font-bold ">Details</h1>
          <p class="text-gray-500 mt-6 text-sm font-semibold">Uploaded by</p>
          <div class="flex mt-1">
            <span class="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-500 mt-1.5">
              <span class="font-medium leading-none text-white">{{ currentDocument.meta?.nameTag }}</span>
            </span>
            <div class="ml-1.5">
              <p>{{ currentDocument.meta?.userName }}</p>
              <p class="text-gray-400">{{ currentDocument.meta?.userEmail }}</p>
            </div>
          </div>
          <div class="mt-6">
            <div class="grid grid-cols-2 gap-2 text-gray-500 ">
              <div class="text-sm font-semibold">Date Uploaded</div>
              <div class="text-sm font-semibold">Last Modified</div>
              <div class="grid grid-cols-subgrid gap-2 col-span-3">
                <div class="col-start-1 text-xs">{{ currentDocument.meta?.formattedCreatedAt }}</div>
                <div class="col-start-2 text-xs">{{ currentDocument.meta?.formattedUpdatedAt }}</div>
              </div>
            </div>
            <div class="mt-6 grid grid-cols-2 gap-2 text-gray-500 ">
              <div class="text-sm font-semibold">File type</div>
              <div class="text-sm font-semibold">Size</div>
              <div class="grid grid-cols-subgrid gap-2 col-span-3">
                <div class="col-start-1 text-xs">{{ currentDocument.meta?.originMimeType }}</div>
                <div class="col-start-2 text-xs">{{ currentDocument.meta?.formattedSourceSize }}</div>
              </div>
            </div>
          </div>
        </div>
        <hr class="max-w-xs ml-6">
        <div class="p-6">
          <button type="button" @click="downloadAnnotatedPDF"
            class="inline-flex items-center gap-x-1.5 rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_59_475)">
                <path d="M7.99976 9.44491V1.29999" stroke="white" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path
                  d="M14.3993 9.44577V13.5182C14.3993 13.6725 14.338 13.8205 14.2289 13.9296C14.1198 14.0387 13.9718 14.1 13.8175 14.1H2.18188C2.02758 14.1 1.8796 14.0387 1.7705 13.9296C1.66139 13.8205 1.6001 13.6725 1.6001 13.5182V9.44577"
                  stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M10.9103 6.53574L8.00143 9.44464L5.09253 6.53574" stroke="white" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_59_475">
                  <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                </clipPath>
              </defs>
            </svg>
            Download
          </button>
        </div>
      </div>
    </div>
    <div v-else class="document-main min-h-[calc(100svh-155px)] bg-transparent">
      <div class="thumbnail-pages">thumbnails</div>
      <div class="pdf-viewer min-h-[calc(100svh-155px)]">
        <div id="viewerContainer" class="viewer-container bg-transparent">
          <div v-if="isLoading" class="viewer-loader min-h-[calc(100svh-105px)]"></div>
          <div id="viewer" class="document-viewer show-scrollbar bg-transparent" data-role="folia-viewer">
          </div>

        </div>
        <SmartScrollBar v-if="countPages > 0" ref="smartScrollBar" :pdf-viewer="foliaPdfViewer"
          :objects="currentDocument.objects" :selected-object="selectedAnnotations" />
        <SearchWithinDocument v-if="countPages > 0" :pdf-viewer="foliaPdfViewer" />
        <div class="document-zoom">
          <div class="zoom-btn" @click.stop="zoomOut">
            <svg width="10" height="2" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.16634 1.16602L0.833008 1.16602" stroke="#334155" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </div>
          <span>{{ zoomValue }}%</span>
          <div class="zoom-btn" @click.stop="zoomIn">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.99967 0.833984V9.16732M9.16634 5.00065H0.833008" stroke="#334155" stroke-width="1.5"
                stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </div>
        <div class="document-pages">Page {{ currentPage }} / {{ countPages }}</div>
        <div class="document-loading" v-if="!currentDocument.document && isLoading"></div>
      </div>
      <!-- <div class="comments-panel collapsed">comments</div> -->
      <AnnotationPanel @scrollToObject="scrollToObject"></AnnotationPanel>
    </div>
  </div>
  <LeftSideBar :favorites="favoriteDocuments" :userRole="currentDocument.userRole"
    @toggleFavoriteStatus="toggleDocumentFavoriteStatus" />
</template>

<style lang="scss">
.document-viewer {
  position: relative;
  overscroll-behavior: inherit !important;
  overflow: scroll !important;
}

.show-scrollbar::-webkit-scrollbar {
  height: 18px;
  /* Change this to adjust the scrollbar height */
  position: absolute;
  bottom: 0;
  z-index: 100;
}

.show-scrollbar::-webkit-scrollbar-thumb {
  background-color: #aca7a7;
  /* Change this to adjust the scrollbar color */
  border-radius: 10px;
  /* Add round corners to the scrollbar */
}

.show-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Change this to adjust the scrollbar hover color */
}

.page {
  position: relative;
  box-shadow: 0 0 10px 3px #e0e0e0;
}

#viewerContainer::-webkit-scrollbar {
  //appearance: none;
  //width: 2px;
}

#viewerContainer::-webkit-scrollbar-track {
  //appearance: none;
}

#viewerContainer::-webkit-scrollbar-thumb {
  //appearance: none;
}
</style>
<style scoped lang="scss">
.left-side-bar {
  width: 240px;
  min-width: 240px;
  height: 100%;
  background: #FFFFFF;
  border-right: 1px solid #E2E8F0;
}

.document-container {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .document-info {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 100%;
    background: #FFFFFF;

    .document-info-name {
      display: flex;
      align-items: center;
      gap: 5px;

      .info-project-name {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #94A3B8;

        background: url("../../assets/images/project-icon.svg") left center no-repeat;
        padding-left: 20px;
        cursor: pointer;

        &:after {
          content: "/";
          padding-left: 5px;
        }
      }

      .info-file-name {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #334155;
      }
    }

    .document-info-button {
      width: 30px;
      height: 30px;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      outline: none;
      border: none;
      background-color: transparent;
      cursor: pointer;
      color: #64748B;

      &:hover {
        color: #334155;
      }

      &:active {
        transform: translate(1px, 1px);
      }

      &.info {
        background: url("../../assets/images/info.svg") center no-repeat;
        background-size: 16px;
      }

      &.isFavorite {
        background: url("../../assets/images/filled-star.svg") center no-repeat;
        background-size: 16px;
      }

      &.isNotFavorite {
        background: url("../../assets/images/outline-star.svg") center no-repeat;
        background-size: 16px;
      }
    }
  }

  .vertical-divider {
    width: 16px;
    height: 0;
    border: 1px solid #e2e8f0;
    transform: rotate(90deg);
    margin: 0 10px;

    &.invisible {
      border: solid 1px transparent;
    }
  }

  .document-header {
    display: flex;
    justify-content: center;
    background-color: white;
    width: 100%;

    .document-toolbar {
      position: relative;
      height: 45px;
      width: 100%;
      padding-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid #e2e8f0;
      border-bottom: 1px solid #e2e8f0;

      .document-tools {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }

    .document-static-toolbar {
      z-index: 2;
      position: absolute;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px 16px 16px 12px;
      gap: 16px;
      //min-width: 505px;
      height: 56px;
      margin-top: 53px;
      background: #ffffff;
      border: 1px solid #E2E8F0;
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
      border-radius: 100px;
    }

    .document-floating-toolbar {
      z-index: 3;
      position: absolute;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px 16px 16px 16px;
      gap: 16px;
      height: 56px;
      background: #ffffff;
      border: 1px solid #E2E8F0;
      border-radius: 100px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
    }
  }

  .document-main {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    //border: solid red 5px;

    .thumbnail-pages {
      display: none;
      width: 130px;
      height: 100%;
      background: #F1F5F9;
      border-right: 1px solid #E2E8F0;
    }

    .pdf-viewer {
      width: 100%;
      position: relative;

      .viewer-container {

        .document-viewer {
          //background: #F1F5F9;
          //display: flex;
          //flex-direction: column;
          //align-items: center;
          //justify-content: center;
          //gap: 10px;
          //padding: 15px 0;
        }

        .viewer-loader {
          // position: absolute;
          // display: block;
          // left: 0;
          // top: 0;
          // right: 0;
          // bottom: 0;
          // background: url("../../assets/images/loading-icon.gif") center no-repeat;
          // min-height: calc(100vh - 50px);
        }
      }

      .document-zoom {
        position: absolute;
        bottom: 16px;
        left: 16px;
        height: 36px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px;
        gap: 12px;
        background: rgba(148, 163, 184, 0.2);
        ;
        //border: 1px solid #E2E8F0;
        border-radius: 100px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(14px);
        -webkit-backdrop-filter: blur(14px);

        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #64748B;
        user-select: none;

        .zoom-btn {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 24px;
          height: 24px;
        }

        .zoom-btn:hover {
          background-color: #ebebeb;
          border-radius: 50%;
        }
      }

      .document-pages {
        position: absolute;
        bottom: 16px;
        right: 34px;
        min-width: 106px;
        height: 36px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px;
        background: rgba(148, 163, 184, 0.2);
        ;
        //border: 1px solid #E2E8F0;
        border-radius: 100px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(14px);
        -webkit-backdrop-filter: blur(14px);
        white-space: nowrap;

        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #64748B;
      }
    }

    .comments-panel {
      width: 320px;
      height: 100%;
      background: #F1F5F9;
      border-left: 1px solid #E2E8F0;

      &.collapsed {
        width: 0;
      }
    }

    .document-loading {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-image: url('../../assets/images/loading-icon.gif');
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

/* Tablet (landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  .left-side-bar {
    position: absolute;
    left: 0;
    height: 100%;
    z-index: 1000;
  }
}
div:focus { outline: none; }
</style>
