import { GraphqlAPI } from "../core/graphql-api";
import { COLLABORATOR_PERMISSIONS, ROLE_PERMISSIONS } from "../core/constants";
import { SET_CACHE } from "../store/cacheLayer";
import { setApplicationState } from "../store/applicationState";

const graphqlServer = import.meta.env.VITE_GRAPHQL_SERVER_V1;
export const PROJECT_CONSTANT = {
  PROJECT_TYPES: {
    ALL: "ALL",
    PERSONAL: "PERSONAL",
    SHARED_WITH_ME: "SHARED_WITH_ME",
  },
  PROJECTS_PER_REQUEST: 10,
};
export default class ProjectService {
  constructor() {
    this.accessToken = localStorage.getItem("accessToken");
    this.graphqlAPIService = new GraphqlAPI(graphqlServer, this.accessToken);
    this.getProjects = this.getProjects.bind(this);
    this.getProject = this.getProject.bind(this);
    this.uploadDocument = this.uploadDocument.bind(this);
  }

  async getProjects(filters = {}) {
    const {
      projectType = PROJECT_CONSTANT.PROJECT_TYPES.ALL,
      projectsPageSize = PROJECT_CONSTANT.PROJECTS_PER_REQUEST,
      projectsCursor = null,
    } = filters;
    if (projectType === PROJECT_CONSTANT.PROJECT_TYPES.ALL) {
      const data = await this.graphqlAPIService.getProjects_v4({
        projectsPageSize,
        projectsCursor,
      });      
      return formatGetProjectsResponse(data);
    }
  }
  async getProject(filters = {}) {
    try {
      const { projectId = null } = filters;
      if (projectId === null) return [];
      const data = await this.graphqlAPIService.getProject_v4({
        projectId,
      });
      localStorage.setItem("tenantId", data.project?.tenantId || null);
      const formattedData = formatGetProjectResponse(data);      
      // await SET_CACHE.cacheProjects({projects: [formattedData]});
      return formattedData;
    } catch (error) {
      return [];
    }
  }
  async createProject(payload = {}) {
    const { name, description = "" } = payload;
    const data = await this.graphqlAPIService.createProject_v4({
      name,
      description,
    });
    return data;
  }
  async editProject(filters) {
    const { projectId, name, description } = filters;
    return this.graphqlAPIService.updateProjectTitle(
      projectId,
      name,
      description
    );
  }
  async deleteProject(projectId) {
    if (!projectId)
      return { deleted: false, message: "Could not find the Project Id" };
    SET_CACHE.setCurrentProjectLoadingState();
    const response = await this.graphqlAPIService.deleteProject_v4(projectId);
    const { deleted = true, message } = response;
    if (deleted) {
      try {
        SET_CACHE.deleteCachedProject(projectId);
      } catch (error) {}
    }
    return { deleted, message };
  }

  async duplicateProject(projectId) {
    return this.graphqlAPIService.duplicateProject(projectId);
  }

  async getProjectCollaborators(filter) {
    const { projectId, endCursor = null, pageSize = 20 } = filter;
    try {
      const {
        project: {
          collaborators: { edges = [],pageInfo },
          publicStatus = "PUBLIC",
          sessionUserRole = "VIEWER",
        },
      } = await this.graphqlAPIService.getProjectCollaborators_v4({
        projectId,
        endCursor,
        pageSize,
      });
      const collaboratorList = [];
      edges.forEach((edge) => {
        const {
          node: {
            collaboratorRole,
            user: { email, name, profileStatus },
            lastAccessedAt,
          },
        } = edge;
        collaboratorList.push({
          email,
          name,
          role: collaboratorRole,
          profileStatus,
          lastAccessedAt,
        });
      });
      return {
        collaborators: collaboratorList,
        isOwner: sessionUserRole === "OWNER",
        publicStatus,
        pageInfo
      };
    } catch (error) {
      return {
        collaborators: [],
        isOwner: false,
      };
    }
  }
  async shareProject(payload) {
    const { projectId, collaboratorEmail, collaboratorRole } = payload;
    return await this.graphqlAPIService.shareProject_v4({
      projectId,
      collaboratorEmail,
      collaboratorRole,
    });
  }

  async updateProjectVisibility(payload) {
    const { projectId, publicStatus } = payload;
    return await this.graphqlAPIService.updateProjectVisibility_v4({
      projectId,
      publicStatus,
    });
  }

  async uploadDocumentMp(payload) {
    console.log("Uploading multipart");
    return await this.graphqlAPIService.uploadDocumentMp(payload);
  }
  async uploadDocument(payload) {
    return await this.graphqlAPIService.uploadDocument_v4(payload);
  }

  async updateDocumentAnnotationDocId(payload) {
    const { projectId, documentId, annotationDocumentId } = payload;
    return await this.graphqlAPIService.updateDocumentAnnotationDocId(
      projectId,
      documentId,
      annotationDocumentId
    );
  }

  async getDocuments(payload) {
    const {
      projectId,
      pageSize = 20,
      endCursor: cursor = null,
      sortBy = "DATE_ADDED",
      sortDirection = "ASC",
    } = payload;
    try {
      const res = await this.graphqlAPIService.getDocuments({
        projectId,
        pageSize,
        cursor,
        sortBy,
        sortDirection,
      });
      return formatGetDocumentResponse({...res, projectId});
    } catch (error) {
      return { documents: [], hasNextPage: false, endCursor: null };
    }
  }

  async getDocument(payload) {
    const { documentId } = payload;
    try {
      const { document = {} } = await this.graphqlAPIService.getDocumentData({
        documentId,
      });
      const { preview } = document;
      const previewContent = `data:application/pdf;base64,${preview}`;

      return { ...document, preview: previewContent };
    } catch (error) {
      return {};
    }
  }

  async getDocumentAnnotationDocId(payload) {
    const { documentId } = payload;
    try {
      const res = await this.graphqlAPIService.getDocumentAnnotationDocId(
        documentId
      );
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async downloadDocument(payload) {
    try {
      const { documentId, flatten, original = false } = payload;
      if (original) {
        const {
          downloadSourceDocument: { content = null, originMimeType } = {},
        } = await this.graphqlAPIService.downloadSourceDocument_v4({
          ...payload,
        });
        return { content, originMimeType };
      } else {
        return await this.downloadAnnotatedDocument({ documentId, flatten });
      }
    } catch (error) {
      return { content: null };
    }
  }

  async downloadDocumentForPDFJS(payload) {
    try {
      const { downloadDocument: { content = null } = {} } =
        await this.graphqlAPIService.downloadDocument_v4({ ...payload });
      return { content: `data:application/pdf;base64,${content}` };
    } catch (error) {
      return { content: null };
    }
  }
  async downloadAnnotatedDocument(payload) {
    const { documentId, flatten = false } = payload;
    try {
      const {
        downloadAnnotatedDocumentContent: {
          content = null,
          originMimeType,
        } = {},
      } = await this.graphqlAPIService.downloadAnnotatedDocumentContent({
        documentId,
        flatten,
      });
      if (content === null) {
        throw { error: "File content is null" };
      }
      return { content, originMimeType };
    } catch (error) {
      console.log("download error", error);
      throw { error: "server error downloading your file" };
    }
  }

  async deleteDocument(payload) {
    const { documentId, projectId } = payload;
    try {
      const response = await this.graphqlAPIService.deleteDocument_v4({
        documentId,
        projectId,
      });
      // make local db changes
      SET_CACHE.deleteCachedDocument(documentId);
      return true;
    } catch (error) {
      console.log("error", error);
      return false;
    }
  }

  async copyDocumentToProject(payload) {
    const {
      documentId,
      sourceProjectId,
      targetProjectId,
      withAnnotations = true,
    } = payload;
    try {
      const response = await this.graphqlAPIService.copyDocument2AnotherProject(
        documentId,
        sourceProjectId,
        targetProjectId,
        withAnnotations
      );
      return true;
    } catch (error) {
      console.log("error", error);
      return false;
    }
  }

  async updateDocumentName(payload) {
    const { documentId, name, projectId } = payload;
    try {
      await this.graphqlAPIService.updateDocumentTitle(
        projectId,
        documentId,
        name
      );
      return true;
    } catch (error) {
      console.log("error", error);
      return false;
    }
  }

  async MarkDocumentFavorite(payload) {
    const { documentId, tenantId, email, isFavorite } = payload;
    try {
      const response = await this.graphqlAPIService.markDocumentFavorite_v4({
        documentId,
        tenantId,
        email,
        isFavorite,
      });
      setApplicationState.setFavoriteDocumentChange({
        docId: documentId,
        isFavorite,
      });
      return true;
    } catch (error) {
      console.log("error", error);
      return false;
    }
  }

  async getExecutiveSummaryData(payload) {
    try {
      const { projectId = null, invoke = false } = payload;
      const {
        getExecutiveSummary: { summary = "", pending = false, lastAdded } = {},
      } = await this.graphqlAPIService.getExecutiveSummary({
        projectId,
        invoke,
      });
      return { isPending: pending, summary, lastAdded };
    } catch (error) {
      return { isPending: false, summary: "" };
    }
  }

  async getCloudStorages() {
    try {
      return await this.graphqlAPIService.getCloudStorages();
    } catch (error) {
      console.log("Error in cloud storage fetch query...");
    }
  }
}
//TODO: move data formatters to a separate layer
const formatGetProjectsResponse = (response) => {
  const {
    projects: {
      edges = [],
      pageInfo: { hasNextPage = false, endCursor = null },
    },
  } = response;
  const payload = { hasNextPage, endCursor, projects: [] };
  edges.forEach((project) => {
    const {
      node: {
        id,
        name,
        description,
        publicStatus,
        createdAt,
        updatedAt,
        sessionUserRole = "OWNER",
        totalDocumentCount = 0,
        totalCollaboratorCount = 0,
        lastAccessedAt = 0
      } = {},
    } = project;
    let permissions = [];
    ROLE_PERMISSIONS.some((p) => {
      const { role, permissions: value } = p;
      if (role === sessionUserRole) {
        permissions = value;
      }
    });
    const projectData = {
      id,
      name,
      description,
      publicStatus,
      createdAt,
      updatedAt,
      lastAccessedAt,
      permissions,
      userRole: sessionUserRole,
      totalDocuments: totalDocumentCount,
      totalCollaboratorCount,
    };
    payload.projects.push(projectData);
  });
  return payload;
};

const formatGetProjectResponse = (response) => {
  const { project, sessionUserRole } = response;
  let projectPermissions = [];
  ROLE_PERMISSIONS.some((p) => {
    const { role, permissions } = p;
    if (role === project?.sessionUserRole) {
      projectPermissions = permissions;
      return true;
    }
  });
  // project.permissions = [...Object.values(COLLABORATOR_PERMISSIONS)];
  project.permissions = projectPermissions;
  project.userRole = project.sessionUserRole;
  return project;
};

const formatGetProjectCollaboratorsResponse = (response) => {
  return response;
};

const formatGetDocumentResponse = (response) => {
  const {
    project: {
      documents: {
        edges = [],
        pageInfo: { hasNextPage = false, endCursor = null },
      },
    } = {},
    projectId
  } = response;
  const docs = edges.map((e) => {
    const { node: payload = {} } = e;
    const { preview = "", originMimeType = "application/pdf" } = payload;
    payload.preview = `data:image/png;base64,${preview}`;
    payload.origin = { mimeType: originMimeType };
    payload.projectId = projectId;
    return payload;
  });
  return { documents: docs, hasNextPage, endCursor };
};
