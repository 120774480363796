import Dexie from "dexie";

import { reactive } from "vue";
import {setApplicationState} from './applicationState';
const cacheVersion = 1;
const indexDBName = "FOLIA_CACHE";
const db = new Dexie(indexDBName);
db.version(cacheVersion).stores({
  projects: "id,userId,data",
  documents: "id,userId,data",
  images:"id,userId,content,date"
});

export const state = reactive({
  cachedProjects: [],
  cachedDocuments: [],
  indexDB: db,
  currentOpenedProject: {},
  isProjectLoading: true,
  isDocumentsLoading: true,
  cachedStamps: [],
});

export const GET_CACHE = {
  async getCachedProjects() {
    const currentUserEmail = localStorage.getItem("email");
    let projects = [];
    try {
      projects = await db.projects
        .where("userId")
        .equals(currentUserEmail)
        .toArray();
    } catch (error) {}
    return projects.map((p) => p.data);
  },
  async getCachedProject(projectId) {
    let project = null;
    const currentUserEmail = localStorage.getItem("email");

    try {
      project = await db.projects
        .where("userId")
        .equals(currentUserEmail) // First filter by userId
        .filter((project) => project.id === projectId) // Then filter by project id in memory
        .first();
    } catch (error) {}
    return project?.data;
  },
  async getCachedDocument(documentId) {
    let document = null;
    const currentUserEmail = localStorage.getItem("email");

    try {
      document = await db.documents
        .where("userId")
        .equals(currentUserEmail) // First filter by userId
        .filter((doc) => doc.id === documentId) // Then filter by project id in memory
        .first();
    } catch (error) {}
    return document;
  },
  async getCachedDocuments(projectId = null,reload = false) {
    if (reload || state.cachedDocuments.length === 0) {
      let documents = [];
      const currentUserEmail = localStorage.getItem("email");
      try {
        if (projectId) {
          documents = await db.documents
            .where("userId")
            .equals(currentUserEmail)
            .toArray();
        } else {
          documents = await db.documents
            .where("userId")
            .equals(currentUserEmail)
            .toArray();
        }
      } catch (error) {
        console.log("error fetching documents from cache", error);
        
      }
      let docs = [];
      docs = projectId
        ? documents
            .map((d) => d.data)
            .filter((doc) => doc.projectId === projectId)
        : documents.map((d) => d.data);
      state.cachedDocuments = docs;
      return docs;
    }
    return projectId
      ? state.cachedDocuments.filter((d) => d.projectId === projectId)
      : state.cachedDocuments;
  },
  getCachedCurrentOpenedProject() {
    return state.currentOpenedProject;
  },
  getProjectLoadingStatus() {
    return state.isProjectLoading;
  },
  getCachedStamps() {
    try {
      const stamps = JSON.parse(localStorage.getItem("cachedStamps")) ?? [];
      return stamps;
    } catch (error) {
      return [];
    }
  },
  async getCachedImages(imageId) {
    let image = null;
    const currentUserEmail = localStorage.getItem("email");
    try {
      image = await db.images
        .where("userId")
        .equals(currentUserEmail) // First filter by userId
        .filter((doc) => doc.id === imageId) // Then filter by project id in memory
        .first();
    } catch (error) {}
    return image;
  }
};

export const SET_CACHE = {
  async initCache(userEmail) {
    try {
      if (state.indexDB === undefined || state.indexDB === null) {
        const indexDB = new Dexie(indexDBName);
        indexDB
          .version(cacheVersion)
          .stores({ users: `email,name,profile`, projects: "email,projects" });
        // indexDB.users.add({
        //   email: userEmail,
        //   profile: { name: "sahan", profilePicture: null },
        // });
        await indexDB.open();
        state.indexDB = indexDB;
      }
    } catch (error) {
      console.log("error creating index db", error);
    }
  },
  clearCache() {
    // state.indexDB[userEmail].clear();
    const currentUserEmail = localStorage.getItem("email");
    localStorage.setItem(
      "projectCache",
      JSON.stringify({
        [currentUserEmail]: {
          projects: [],
          documents: [],
        },
      })
    );
    return true;
  },
  setCurrentProjectLoadingState() {
    state.isProjectLoading = true;
    return;
  },
  setDocumentsLoadingState() {
    state.isDocumentsLoading = true;
    return;
  },
  async cacheProjects({ projects, user }) {
    // try saving to indexDB
    const currentUserEmail = localStorage.getItem("email");
    try {
      const serializableProjects = projects.map((p) => ({
        id: p.id,
        data: JSON.parse(JSON.stringify(p)),
        userId: currentUserEmail,
      }));
      await db.projects.bulkPut(serializableProjects);
    } catch (error) {
      console.log("caching error for indexDB", error);
    }
  },
  cacheCurrentProject({ project, user }) {
    state.currentOpenedProject = project;
    state.isProjectLoading = false;
    try {
      localStorage.setItem("currentlyOpenedProject", JSON.stringify(project));
    } catch (error) {}
    // saveCurrentProjectCachedDataInStorage();
  },
  async updateDoc({ documentId, data }) {
    try {
      const sanitizedData = JSON.parse(JSON.stringify(data));  
      await db.documents.update(documentId, { data:sanitizedData });
    } catch (error) {
      console.log("cache update error for document.",error);
    }
  },
  async cacheDocuments({ documents: docs }) {
    const currentUserEmail = localStorage.getItem("email");
    try {
      const serializableDocs = docs.map((d) => ({
        id: d.id,
        data: JSON.parse(JSON.stringify(d)),
        userId: currentUserEmail,
      }));
      await db.documents.bulkPut(serializableDocs);
    } catch (error) {
      console.log("caching error for indexDB--->Documents", error);
    }
    state.isDocumentsLoading = false;
  },
  async deleteCachedProject(projectId) {
    try {      
      await db.projects.delete(projectId); // Delete by primary key (id)
      // delete docs inside
      const docs = await GET_CACHE.getCachedDocuments(projectId,true);
      docs.forEach(d=>{ 
        if(d?.projectId === projectId){
           db.documents.delete(d.id);
        }
      })
      return true;
    } catch (error) {
      console.error("Error deleting project from IndexedDB:", error);
      return false;
    }
  },
  async updateProjectLastAccessedAt(projectId) {
    try {
      const currentUserEmail = localStorage.getItem("email");
      const project = await db.projects
        .where("userId")
        .equals(currentUserEmail)
        .filter((project) => project.id === projectId)
        .first();
      if (project) {
        project.data.lastAccessedAt = new Date().toISOString();
        await db.projects.put(project);
        console.log("project lastAccessedAt updated...",new Date().toISOString());
        
      }
    } catch (error) {
      console.error("Error updating project's lastAccessedAt:", error);
    }
  },
  async deleteCachedDocument(documentId) {
    try {      
      await db.documents.delete(documentId); // Delete by primary key (id)
      console.log("document deleted...");
      setApplicationState.setDocumentReloadState(true);
      return true;
    } catch (error) {
      console.error("Error deleting document from IndexedDB:", error);
      return false;
    }
  },
  async cacheImages(images) {
    try {
      const currentUserEmail = localStorage.getItem("email");
      const serializableProjects = images.map((p) => ({
        id: p.id,
        content: p.content,
        userId: currentUserEmail,
        date: Date.now()
      }));  
      await db.images.bulkPut(serializableProjects);
      return;
    } catch (error) {
      console.log("image caching error",error);
      return;
      
    }
    
  },
  cacheStamps(stampArray) {
    try {
      const stamps = JSON.parse(localStorage.getItem("cachedStamps")) ?? [];
      stampArray.forEach((stampObj) => {
        const { id: newStampId } = stampObj;
        if (!stamps.some((s) => s.id === newStampId)) {
          stamps.push(stampObj);
        }
      });
      localStorage.setItem("cachedStamps", JSON.stringify(stamps));
    } catch (error) {}
  },
  deleteStampFromCache(stampId) {
    try {
      const stamps = JSON.parse(localStorage.getItem("cachedStamps")) ?? [];
      const modifiedStamps = stamps.filter((s) => s.id !== stampId);
      localStorage.setItem("cachedStamps", JSON.stringify(modifiedStamps));
    } catch (error) {}
  },
  makeRecentStamp(id) {
    try {
      const stamps = JSON.parse(localStorage.getItem("cachedStamps")) ?? [];
      const modifiedStamps = stamps.map((s) => {
        if (s.id === id) {
          s.recent = Date.now();
        }
        return s;
      });
      localStorage.setItem("cachedStamps", JSON.stringify(modifiedStamps));
    } catch (error) {}
  },
  updateStamp(stamp) {
    try {
      const stamps = JSON.parse(localStorage.getItem("cachedStamps")) ?? [];
      const modifiedStamps = stamps.map((s) => {
        if (s.id === stamp.id) {
          const newData = {
            ...s,
            ...stamp,
          };
          return newData;
        }
        return s;
      });
      localStorage.setItem("cachedStamps", JSON.stringify(modifiedStamps));
    } catch (error) {}
  },
};

const saveCurrentProjectCachedDataInStorage = (payload) => {
  try {
    const storedData = localStorage.getItem("projectCache");
    const currentUserEmail = localStorage.getItem("email");
    if (storedData) {
      const projectData = JSON.parse(storedData);
      if (projectData[currentUserEmail]) {
        projectData[currentUserEmail].currentOpenedProject =
          state.currentOpenedProject;
        localStorage.setItem("projectCache", JSON.stringify(projectData));
      }
    }
  } catch (error) {
    this.clearCache();
  }
};

const saveProjectsCachedDataInStorage = () => {
  try {
    const storedData = localStorage.getItem("projectCache");
    const currentUserEmail = localStorage.getItem("email");
    if (storedData) {
      const projectData = JSON.parse(storedData);
      if (projectData[currentUserEmail]) {
        projectData[currentUserEmail].projects = state.cachedProjects;
        localStorage.setItem("projectCache", JSON.stringify(projectData));
      }
    } else {
      localStorage.setItem(
        "projectCache",
        JSON.stringify({
          [currentUserEmail]: { projects: state.cachedProjects },
        })
      );
    }
  } catch (error) {
    this.clearCache();
  }
};

const saveDocumentsCachedDataInStorage = () => {
  try {
    const storedData = localStorage.getItem("projectCache");
    const currentUserEmail = localStorage.getItem("email");
    if (storedData) {
      const projectData = JSON.parse(storedData);
      if (projectData[currentUserEmail]) {
        projectData[currentUserEmail].documents = state.cachedDocuments;
        localStorage.setItem("projectCache", JSON.stringify(projectData));
      }
    } else {
      localStorage.setItem(
        "projectCache",
        JSON.stringify({
          [currentUserEmail]: { documents: state.cachedDocuments },
        })
      );
    }
  } catch (error) {
    this.clearCache();
  }
};
const store = { GET_CACHE, SET_CACHE };
export default store;
